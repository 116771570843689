import { FC } from "react";
import { IBaseIconProps } from "./types";

export const PlusOneIcon: FC<IBaseIconProps> = ({
  size = 24,
  color = '#000'
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.7559 5.30029H18.9981V18.5001H16.5467V7.35569H13.7559V5.30029Z" fill="black" fillOpacity="0.8" />
      <path
        d="M12.7711 10.9572V12.8428H9.43346V16.1428H7.41578V12.8428H4.07812V10.9572H7.41578V7.65723H9.43346V10.9572H12.7711Z"
        fill={color} fillOpacity="0.8" />
    </svg>
  )
}