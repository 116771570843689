import TextOverflow from "../../../utils/text-overflow/TextOverflow";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon } from "../../../img";
import NewsDateIcon from "../../../img/icons/news/NewsDateIcon";
import { FC } from "react";
import { getYearMonthDay } from "../../../utils";

export interface NewsCardProps {
    title: string;
    image: string;
    category_title: string;
    date: any;
    views: number;
    likes_count: number;
    comments_count: number;
    isLiked: boolean,
}

interface INewsCard {
    isLikeIcon?: boolean;
    data: NewsCardProps
}

export const NewsCard: FC<INewsCard> = ({
    isLikeIcon = false,
    data
}) => {
    const isMobile = window.screen.width <= 600

    const {
        title,
        image,
        category_title,
        date,
        views,
        likes_count,
        comments_count,
        isLiked
    } = data

    return (
        <div className='news-card'>
            <img src={ image } />

            <div className='news-card__infoBox'>

                { isLikeIcon ? (
                        <div className='news-card__infoBox__top'>
                            <h5>{ TextOverflow({title, lines: 3}) }</h5>

                            <div
                                // onClick={ () => {
                                //     handleLike(item.id)
                                // } }
                                className={ `employees-block__likeIcon ${ isLiked ? 'active' : '' }` }
                            >
                                <HeartIcon />
                            </div>
                        </div>
                    ) :
                    <h5>{ TextOverflow({title, lines: 3}) }</h5>
                }

                { category_title ? <div className={ `news-card__catTitleStyle` }>{ category_title }</div> : null }

                <div className='news-card__content'>
                    <div className='news-card__dateBox'>
                        <NewsDateIcon size={ '1.25rem' }
                                      style={ {marginRight: isMobile ? '0.375rem' : '0.5rem', minWidth: '1.25rem'} } />
                        <div>{ getYearMonthDay(date * 1000) }</div>
                    </div>

                    <div className='news-card__stats'>
                        <div className='news-card__stats__block'>
                            <EyeIcon />

                            <h1>{ views }</h1>
                        </div>

                        <div className='news-card__stats__block'>
                            <CommentIcon />

                            <h1>{ comments_count }</h1>
                        </div>

                        <div className='news-card__stats__block'>
                            <LikeIcon color='#00A7B5' />

                            <h1>{ likes_count }</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}