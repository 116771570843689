import { FC } from "react";
import { IBaseIconProps } from "./types";

export const RejectedIcon: FC<IBaseIconProps> = ({
  size = 24,
  color = '#FF0000',
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8891 9.52513C16.2796 9.1346 16.2796 8.50144 15.8891 8.11091C15.4986 7.72039 14.8654 7.72039 14.4749 8.11091L15.8891 9.52513ZM8.11091 14.4749C7.72039 14.8654 7.72039 15.4986 8.11091 15.8891C8.50144 16.2796 9.1346 16.2796 9.52513 15.8891L8.11091 14.4749ZM14.4749 15.8891C14.8654 16.2796 15.4986 16.2796 15.8891 15.8891C16.2796 15.4986 16.2796 14.8654 15.8891 14.4749L14.4749 15.8891ZM9.52513 8.11091C9.1346 7.72039 8.50144 7.72039 8.11091 8.11091C7.72039 8.50144 7.72039 9.1346 8.11091 9.52513L9.52513 8.11091ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM14.4749 8.11091L11.2929 11.2929L12.7071 12.7071L15.8891 9.52513L14.4749 8.11091ZM11.2929 11.2929L8.11091 14.4749L9.52513 15.8891L12.7071 12.7071L11.2929 11.2929ZM15.8891 14.4749L12.7071 11.2929L11.2929 12.7071L14.4749 15.8891L15.8891 14.4749ZM12.7071 11.2929L9.52513 8.11091L8.11091 9.52513L11.2929 12.7071L12.7071 11.2929Z"
        fill={color} />
    </svg>
  )
}