import React, { FC, useState } from "react";
import { IServicesApplications } from "../../../api/requests/services/interface";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../ui/form/DatePickerForm";

interface DropdownItem {
  label: string;
  value: number | string;
}

interface IServicesApplicationsList {
  data: IServicesApplications[]
}
export const ServicesApplicationsList: FC<IServicesApplicationsList> = ({
  data
}) => {
  const [ categoryList, setCategoryList ] = useState<DropdownItem[]>([
    {
      label: "Спорт",
      value: 0
    },
    {
      label: "Инновации",
      value: 1
    },
    {
      label: "Творчество",
      value: 2
    }
  ]);

  const {
    handleSubmit,
    control,
    watch,
    register,
    reset,
    formState: {isValid},
    setValue,
  } = useForm<any>();

  return (
    <div className='services-education-wrapper'>
      <div className='services-education-wrapper__header'>
        <div className='services-education-wrapper__buttons'>
          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="type"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <Dropdown
                  className={ "form-select" }
                  placeholder={ "Все" }
                  label={ "Статус" }
                  options={ [
                    {
                      label: "Все",
                      value: "all"
                    },
                    ...categoryList
                  ] }
                  value={ value }
                  onChange={ onChange }
                />
              ) }
            />
          </div>

          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="date_start"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Выберете дату"
                }
              }}
              render={({ field: { onChange, value } }) => (
                <DatePickerForm
                  value={value}
                  className=""
                  label="Дата обучение"
                  placeholder={"Не выбрано"}
                  onChange={onChange}
                  isIcon
                  isRightIcon
                />
              )}
            />
          </div>

          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="date_start"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Выберете дату"
                }
              }}
              render={({ field: { onChange, value } }) => (
                <DatePickerForm
                  value={value}
                  className=""
                  label="Дата заявки"
                  placeholder={"Не выбрано"}
                  onChange={onChange}
                  isIcon
                  isRightIcon
                />
              )}
            />
          </div>
        </div>

        <div className='services-education-wrapper__header__button'>
          Подать заявку
        </div>
      </div>

      <div className='services-applications'>
        <div className='services-applications__header'>
          <div className='column'>ID заявки</div>
          <div className='column colApp'>Дата обучение</div>
          <div className='column colExecutor'>Дата заявки</div>
          <div className='column colDate'>Файл</div>
          <div className='column colDate'>Статус</div>
        </div>

        <div className='services-applications__body'>
          {data?.map((item, idx) => (
            <div className='services-applications__body__block' key={idx}>
              <p className='services-applications__body__block__id width'>{item.id}</p>

              <p className='services-applications__body__block__date width'>{item.date_education}</p>

              <p className='services-applications__body__block__date width'>{item.date_application}</p>

              <p className='services-applications__body__block__file width'>Скачать</p>

              <div className='services-applications__body__block__status width'>
                <p className=''>{item.status}</p>
              </div>
            </div>
          ))}
        </div>

        <div className='services-applications__download'>
          <p>
            Показать еще
          </p>
        </div>
      </div>
      </div>
  )
}