import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../ui/form/DatePickerForm";
import { AcceptedIcon, RejectedIcon } from "../../../img";

interface DropdownItem {
  label: string;
  value: number | string;
}

interface IServicesApplicationsHistory {
  isAdmin: boolean,
  isHr: boolean,
}

export const ServicesApplicationsHistory: FC<IServicesApplicationsHistory> = ({
  isAdmin,
  isHr,
}) => {
  const [ categoryList, setCategoryList ] = useState<DropdownItem[]>([
    {
      label: "Спорт",
      value: 0
    },
    {
      label: "Инновации",
      value: 1
    },
    {
      label: "Творчество",
      value: 2
    }
  ]);

  const {
    handleSubmit,
    control,
    watch,
    register,
    reset,
    formState: {isValid},
    setValue,
  } = useForm<any>();

  const [ data, setData ] = useState(isHr ? [
      {
        id: 2314,
        date_education: '21.10.2024',
        date_application: '21.07.2024',
        status: 1,
        admin: {
          image: '/testImages/people/Image-2.png',
          name: 'Воробьёв Илья Романович'
        },
        users: [
          {
            image: '/testImages/people/Image-3.png',
            name: 'Воробьёв Илья Романович',
            isAccepted: 2,
          },
        ]
      },
      {
        id: 2314,
        date_education: '21.10.2024',
        date_application: '21.07.2024',
        status: 0,
        admin: {
          image: '/testImages/people/Image-2.png',
          name: 'Воробьёв Илья Романович'
        },
        users: [
          {
            image: '/testImages/people/Image-3.png',
            name: 'Воробьёв Илья Романович',
            isAccepted: 2
          },
        ]
      }
    ] :
    [
      {
        id: 2314,
        date_education: '21.10.2024',
        date_application: '21.07.2024',
        status: 1,
        admin: {
          image: '/testImages/people/Image-2.png',
          name: 'Воробьёв Илья Романович'
        },
        users: [
          {
            image: '/testImages/people/Image-3.png',
            name: 'Воробьёв Илья Романович',
            isAccepted: 2,
          },
          {
            image: '/testImages/people/Image-4.png',
            name: 'Воробьёв Илья Романович',
            isAccepted: 1
          },
          {
            image: '/testImages/people/Image-5.png',
            name: 'Воробьёв Илья Романович',
            isAccepted: 0
          }
        ]
      },
      {
        id: 2314,
        date_education: '21.10.2024',
        date_application: '21.07.2024',
        status: 0,
        admin: {
          image: '/testImages/people/Image-2.png',
          name: 'Воробьёв Илья Романович'
        },
        users: [
          {
            image: '/testImages/people/Image-3.png',
            name: 'Воробьёв Илья Романович',
            isAccepted: 1
          },
          {
            image: '/testImages/people/Image-4.png',
            name: 'Воробьёв Илья Романович',
            isAccepted: 1
          },
          {
            image: '/testImages/people/Image-5.png',
            name: 'Воробьёв Илья Романович',
            isAccepted: 0
          }
        ]
      }
    ])
  return (
    <div className='services-education-wrapper'>
      <div className='services-education-wrapper__header'>
        <div className='services-education-wrapper__buttons'>
          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="type"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <Dropdown
                  className={ "form-select" }
                  placeholder={ "Все" }
                  label={ "Отправитель" }
                  options={ [
                    {
                      label: "Все",
                      value: "all"
                    },
                    ...categoryList
                  ] }
                  value={ value }
                  onChange={ onChange }
                />
              ) }
            />
          </div>

          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="type"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <Dropdown
                  className={ "form-select" }
                  placeholder={ "Все" }
                  label={ "Руководитель" }
                  options={ [
                    {
                      label: "Все",
                      value: "all"
                    },
                    ...categoryList
                  ] }
                  value={ value }
                  onChange={ onChange }
                />
              ) }
            />
          </div>

          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="date_start"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: "Выберете дату"
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <DatePickerForm
                  value={ value }
                  className=""
                  label="Дата обучение"
                  placeholder={ "Не выбрано" }
                  onChange={ onChange }
                  isIcon
                  isRightIcon
                />
              ) }
            />
          </div>

          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="date_start"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: "Выберете дату"
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <DatePickerForm
                  value={ value }
                  className=""
                  label="Дата заявки"
                  placeholder={ "Не выбрано" }
                  onChange={ onChange }
                  isIcon
                  isRightIcon
                />
              ) }
            />
          </div>
        </div>
      </div>

      <div className='services-history'>
        <div className='services-history__header'>
          <div className='column colId'>ID заявки</div>
          <div className='column colDate'>Дата обучение</div>
          <div className='column colDate'>Дата заявки</div>
          <div className='column colAdmin'>Руководитель</div>
          <div className='column colUser'>Согласующие</div>
          <div className='column colStatus'>Статус</div>
        </div>

        <div className='services-history__content'>
          { data.map((
            item,
            idx
          ) => (
            <div className='services-history__block' key={ idx }>
              <p className='services-history__block__id'>{ item.id }</p>

              <p className='services-history__block__date'>{ item.date_education }</p>

              <p className='services-history__block__date'>{ item.date_application }</p>

              <div className='services-history__block__admin'>
                <img className='services-history__block__admin__image' src={ item.admin.image } alt="" />

                <h5 className='services-history__block__admin__name'>{ item.admin.name }</h5>
              </div>

              <div className='services-history__block__users'>
                { item.users.map((
                  user,
                  idx
                ) => (
                  <div className='services-history__block__user' key={ idx }>
                    <div className='services-history__block__user__left'>
                      <img src={ user.image } alt="" />

                      <h5>{ user.name }</h5>
                    </div>

                    { user.isAccepted === 1 && (
                      <AcceptedIcon />
                    ) }

                    { user.isAccepted === 0 && (
                      <RejectedIcon />
                    ) }
                  </div>
                )) }
              </div>

              <div className='services-history__block__status'>
                <div
                  className={ `services-history__block__status__button ${ item.status == 1 ? 'accepted' : 'rejected' }` }>
                  { item.status === 1 ? 'Одобрено' : 'Отклонено' }
                </div>
              </div>
            </div>
          )) }
        </div>
      </div>
    </div>
  )
}