import { ProfileStarIcon, ProfileBalanceIcon } from "../../img/icons/layout-components";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getProfileData } from "../../redux";

interface IStatsCard {
  data?: any
}
export const StatsCard: FC<IStatsCard> = ({
  data
}) => {
  const profileData = useSelector(getProfileData)

    return (
        <div className='layout-stats'>
            <div className='layout-stats__block'>
                <div style={{background: '#97CA001A'}} className='layout-stats__circle'><ProfileStarIcon/> </div>
                <p>{profileData?.user_points}</p>
            </div>

            <div className='layout-stats__block'>
                <div className='layout-stats__circle' style={{background: '#FF7E001A'}}> <ProfileBalanceIcon/></div>
                <p>{profileData?.user_balance}</p>
            </div>
        </div>
    )
}
