import pad from '../pad/pad'
import { ReactNode } from "react";
export function getYearMonthDayAndTime(date: string) {
    const getDate = new Date(date)
    const year = getDate.getFullYear()
    const month = getDate.getMonth() + 1
    const day = getDate.getDate()
    const hour = getDate.getHours()
    const minutes = getDate.getMinutes()

    return `${pad(day)}-${pad(month)}-${year} ${pad(hour)}:${pad(minutes)}`
}

export function getYearMonthDay(date: any) {
    const getDate = new Date(date);
    const year = getDate.getFullYear();
    const month = getDate.getMonth() + 1;
    const day = getDate.getDate();

    return `${year}-${pad(month )}-${pad(day)}`;
}

export const modDate = (month: string): string => {
    switch (month) {
        case '01':
            return 'января'

        case '02':
            return 'февраля'

        case '03':
            return 'марта'

        case '04':
            return 'апреля'

        case '05':
            return 'мая'

        case '06':
            return 'июня'

        case '07':
            return 'июля'

        case '08':
            return 'августа'

        case '09':
            return 'сентября'

        case '10':
            return 'октября'

        case '11':
            return 'ноября'

        case '12':
            return 'декабря'

        default:
            return 'января'
    }
}

// from Mon Jan 19 1970 15:13:17 GMT-0500 (-05) to { month: '01', day: '01' etc}
export const calcDate = (date: number) => {
    const dateJs = date ? new Date(date * 1000) : new Date()
    const isFuture = new Date(date).getTime() > new Date().getTime() / 1000

    const curDay = new Date().getDate()
    const curMonth = new Date().getMonth() + 1
    const curYear = new Date().getFullYear()

    const targetDay = new Date(dateJs).getDate()
    const targetMonth = new Date(dateJs).getMonth() + 1
    const targetYear = new Date(dateJs).getFullYear()

    const isSameMonth = targetMonth === curMonth && curYear === targetYear
    const checkIsToday = isSameMonth && curDay === targetDay
    const checkIsYesterday = isSameMonth && curDay - 1 === targetDay

    return {
        month: `${dateJs.getMonth() + 1}`.padStart(2, '0'),
        day: `${dateJs.getDate()}`.padStart(2, '0'),
        hour: `${dateJs.getHours()}`.padStart(2, '0'),
        minute: `${dateJs.getMinutes()}`.padStart(2, '0'),
        year: `${dateJs.getFullYear()}`.padStart(2, '0'),
        isToday: checkIsToday,
        isYesterday: checkIsYesterday,
        isFuture,
    }
}
