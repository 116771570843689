import React, { useState } from "react";
import { ArrowRightIcon } from "../../img";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMainPageData } from "../../redux";

interface IBirthdaysCard {
    id: number,
    photo: string,
    name: string,
    icon: string,
}

export const BirthdaysCard = () => {
    const navigate = useNavigate()
    const isMobile = window.screen.width < 1020;

    const birthdaysData = useSelector(getMainPageData)?.birthdays.today

    const [ birthdaysContent, setBirthdaysContent ] = useState<IBirthdaysCard[]>([
        {
            id: 1,
            photo: '/testImages/people/Image-3.png',
            name: 'Яковлев Василий',
            icon: '/testImages/birthdays/birthdays-1.png'
        },
        {
            id: 2,
            photo: '/testImages/people/Image-2.png',
            name: 'Баранов Виктор',
            icon: '/testImages/birthdays/birthdays-2.png'
        },
        {
            id: 3,
            photo: '/testImages/people/Image-3.png',
            name: 'Волков Борис',
            icon: '/testImages/statuses/statuses-1.png'
        },
        {
            id: 4,
            photo: '/testImages/people/Image-4.png',
            name: 'Титов Дмитрий',
            icon: '/testImages/birthdays/birthdays-1.png'
        },
        {
            id: 5,
            photo: '/testImages/people/Image-5.png',
            name: 'Алексеев Илья',
            icon: '/testImages/birthdays/birthdays-1.png'
        },
        {
            id: 6,
            photo: '/testImages/people/Image-7.png',
            name: 'Новиков Игорь',
            icon: '/testImages/birthdays/birthdays-1.png'
        },
        {
            id: 7,
            photo: '/testImages/people/Image-8.png',
            name: 'Герасимов Валерий',
            icon: '/testImages/birthdays/birthdays-1.png'
        },
    ])

    const countOverallList = () => {
        const screen = window.screen.width
        if (window.screen.width >= 870 && window.screen.width < 1020) {
            return 5
        }
        else if ( window.screen.width >= 570 && window.screen.width < 870) {
            return 4
        }
        else if (window.screen.width < 570) {
            return 3
        }
    }

    return (
        <>
            { isMobile ? (
                    <div className='layout-birthdaysMobile__birthdaysBody'>
                        <div className='feed-controls'>
                            <h3 >Дни рождения</h3>

                            <div onClick={() => navigate('birthdays')}>
                                <h4>Показать все</h4>
                                <ArrowRightIcon color='#000'/>
                            </div>
                        </div>

                        <div className='layout-birthdaysMobile__birthdaysList'>
                            { birthdaysData && birthdaysData?.slice(0, countOverallList()).map((
                                item: any,
                                idx: number
                            ) => (
                                <div key={ idx }>
                                    <img src={item.photo} alt="" />

                                    <p>{ item.name }</p>
                                </div>
                            )) }
                        </div>
                    </div>
                )
                : (
                    <div className='layout-birthdays'>
                        <div className='layout-birthdays__header'>
                            <div className='layout-birthdays__titles'>
                                <h1>День Рождения!</h1>

                                <p>25 июля, 2024</p>
                            </div>

                            <img src="/testImages/badges/badges-5.png" alt=""
                                 className='layout-birthdays__header-image' />
                        </div>

                        <div className='layout-birthdays__content'>
                            { birthdaysData.map((
                                item: any,
                                idx: number
                            ) => (
                                <div key={ idx } className='layout-birthdays__block'>
                                    {item.photo ? (
                                      <img src={ item?.photo } alt="" />
                                    ): (
                                      <div className='layout-birthdays__block__noneImage'>
                                          {item.name[0]}
                                      </div>
                                    )}
                                </div>
                            )) }
                        </div>

                        <button onClick={() => navigate('/birthdays')} className='layout-birthdays__button'>
                            Показать все
                        </button>
                    </div>
                )
            }

        </>
    )
}