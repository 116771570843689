import { FC } from "react";
import { IBaseIconProps } from "./types";

export const ReturnIcon: FC<IBaseIconProps> = ({
  size = 24,
  color = 'black'
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2049 5.99966L20 9.84609M20 9.84609L16.2049 13.6925M20 9.84609L7.33272 9.84609C5.49168 9.84609 3.99922 11.3385 3.99922 13.1796L3.99922 14.6667C3.99922 16.5078 5.49168 18.0002 7.33272 18.0002L11.9996 18.0002"
        stroke={color} strokeOpacity="0.8" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" />
    </svg>
  )
}